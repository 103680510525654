import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme)=> ({
    container : {
        backgroundColor : theme.palette.secondary.main,
        height : "100vh",
        width : "100%",
        display : "flex",
        flexDirection : "column",
        justifyContent : "center",
        alignItems : "center",
        position : "absolute",
        zIndex : theme.zIndex.appBar + 1000,
        top : 0,
        right : 0,
        "& .MuiAvatar-root" : {
            marginBottom : 16,
            width : 60,
            height : 60,
            backgroundColor : theme.palette.primary.main
        },
        "& .MuiCheckbox-root" : {
            paddingLeft : 0,
            marginLeft : -2
        },
        "& a" : {
            textDecoration : "none",
            color : "#efefef"
        }
    },
    loginForm : {
        backgroundColor : "#fff",
        padding : 24,
        borderRadius : 4,
    },
    passInput : {
        "& .MuiInputBase-root" : {
            borderRadius : "2px 0px 0px 2px"
        },
        "& .MuiBox-root" : {
            borderRadius :"0px 2px 2px 0px" ,
            display : "flex",
            width : 40,
            justifyContent : "center",
            alignItems : "center",
            backgroundColor :"#cfcfcf",
            cursor : "pointer"
        }
    }
}))