import React,{useState,useContext,useEffect} from 'react'
import { Button, Box, CircularProgress,Typography} from "@material-ui/core"
import { useParams } from 'react-router-dom';
import { useStyles } from '../commonStyle'
import { useSelector} from "react-redux";
import {Context} from "../../context/AppContext"
import AlertModal from "../../components/AlertModal/AlertModal"
import SubHeader from "../../components/SubHeader/SubHeader"
import Information from './Information';

import { getCustomers} from '../../APi/api';
import empty from '../../assets/icons/empty.png'
import OverView from './OverView';
function Customer(props) {
    const classes = useStyles()
    const appProps = useSelector(state => state);
    
    const {id} = useParams()
    console.log("id",id);
    const {alertModal, setAlertModal} = useContext(Context)
    
    const [isCreating, setCreating] = useState(false)
    const [isLoading,setLoading] = useState(true)
    const [data,setData] = useState(null)
    const [newVariables,setNewVariables] = useState({
        id:id
    })
    const [variables, setVariables] = React.useState({
        email: "",
        firstName:"",
        password: "",
        lastName: "",
        locale: "",
        province: "",
        phone: "",
        addresses:{}
    });
    const [addresses, setAddresses] = React.useState({
        address1: "",
        address2: "",
        city: "",
        firstName:"",
        lastName: "",
        phone: "",
        province: "",
        zip: ""
    });
    const handleChange = (target,value) => {
        setVariables({ ...variables, [target]: value });
        setNewVariables({ ...newVariables, [target]: value });
    };
    const handleAddressChange = (target,value) => {
        setAddresses({ ...addresses, [target]: value });
    };
    const showAlert = (type,message)=>{
        setAlertModal({type:type,message:message})
    }
    const fetchCustomer=async()=>{
        const result = await getCustomers(appProps.apolloClient,{id:id})
        console.log("resultresult",result);
        if(result.success) {
            const customerData = result.data.edges[0].node
            setData(customerData)
            setVariables({ 
                userName: customerData.info.userName,
                email: customerData.info.email,
            });
            setLoading(false)
        } else {
            setAlertModal({type:'Error',message:result.error.message})
            setVariables(null)
            setLoading(false)
        }
    }
    useEffect(() => {
        try {
            fetchCustomer()
        } catch (error) {
            
        }
    },[appProps])
    return (
        <Box className={classes.container}>
            {isLoading ?
            <Box style={{display:"flex",alignItems:"center", justifyItems:"center",flexDirection:"column"}}>
                <CircularProgress color="primary" />
            
            </Box>:(variables?
                <Box className={classes.container}>
                    <SubHeader locationName={variables.title} />
                    <div className={classes.mainContainer}>
                        <Box className={classes.leftSide}>
                            <Information variables={data} handleChange={handleChange} />
                        </Box>
                        <Box className={classes.rightSide}>
                            <OverView variables={data} handleChange={handleChange} id={id}/>
                        </Box>
                    </div>
                </Box>:
                <Box style={{display:"flex",alignItems:"center", justifyItems:"center",flexDirection:"column"}}>
                    <img src={empty} style={{width:150,height:150,margin:20}} />
                    <Typography>No customer has been found</Typography>
                </Box>
                )
            }
            {alertModal && <AlertModal/>}
        </Box>
    )
} 
export default Customer