import React, { useContext } from 'react'
import { Typography, Box} from "@material-ui/core"
import { useStyles } from "./styles"
import {Context} from "../../context/AppContext"
import CloseIcon from "@material-ui/icons/Close"
function AlertModal(props) {
    const classes = useStyles()
    const {alertModal, setAlertModal} = useContext(Context)
    return (
        <div className={classes.modalContainer} >
            <Box className={classes.compaigneModal} >
                <Box display="flex" justifyContent="space-between" >
                    <Typography variant="h5" >{alertModal.type}</Typography>
                    <CloseIcon onClick={()=> setAlertModal(null)} />
                </Box>
                <Box bgcolor="#fff" p={1} ></Box>
                <Typography>{alertModal.message}</Typography>
            </Box>
        </div>
    )
}

export default AlertModal
