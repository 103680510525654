import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme)=> ({
    container : {
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
        width:"100%"
    },
    mainContainer:{
        display : "flex",
        flexDirection:'row',
        width:"100%"
    },
    leftSide:{
        display : "flex",
        width:"70%",
        flexDirection : "column",
        alignItems : "center",
    },
    rightSide:{
      display : "flex",
      width:"30%",
      flexDirection : "column",
      alignItems : "center",
    },
    align:{
      display : "flex",
      flexDirection : "row",
      alignItems : "center",
      justifyContent:"space-between"
    },
    cardForm : {
        backgroundColor : "#fff",
        padding : 24,
        borderRadius : 4,
        width:"95%",
        marginTop:10,
        boxShadow : "1px 1px 3px #ccc",
    },
    centerContent:{
      display:'flex',
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center"
    },
    addBtn : {
      display : "flex",
      justifyContent : "center!important",
      alignItems : "center",
      padding:10,
      marginTop:20,
      width:"50%",
      border:"1px solid black",
      cursor : "pointer",
      "& .MuiSvgIcon-root" : {
          fontSize : 20
      }
    },
    margin: {
        margin: theme.spacing(1),
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: '100%',
      },
      formControl:{
        width: '100%',
      },
      ruleSelect:{
        width: '100%',
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between",
        alignItems:"center"
      },
      passInput : {
        "& .MuiInputBase-root" : {
            borderRadius : "2px 0px 0px 2px"
        },
        "& .MuiBox-root" : {
            borderRadius :"0px 2px 2px 0px" ,
            display : "flex",
            width : 40,
            justifyContent : "center",
            alignItems : "center",
            backgroundColor :"#cfcfcf",
            cursor : "pointer"
        }
    }
}))