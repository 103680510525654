import React,{useState,useContext} from 'react'
import { Box,Typography } from "@material-ui/core"
import { useStyles } from '../styles'
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable/DataTable"
import { useSelector} from "react-redux";
import {Context} from "../../context/AppContext"
import AlertModal from "../../components/AlertModal/AlertModal"
import SubHeader from "../../components/SubHeader/SubHeader"
import {getPriceRules} from '../../APi/api'
import NoDiscount from '../../assets/icons/discount.svg';
function Discounts() {
    const classes = useStyles()
    const history = useNavigate()
    const {alertModal, setAlertModal} = useContext(Context)
    const appProps = useSelector(state => state);
    let tableHeaders = ["Code","Status","Usage","Start Date"]
    const [rows,setRows] = useState(null)
    const [pageInfo,setPageInfo] = useState({})
    const [totalCount,setTotalCount] = useState(0)
    const [first,setFirst] = useState(10)
    const [isLoading,setLoading] = useState(false)
    function createData(code,status,usage,startDate,id){
        return { code,status,usage,startDate,id}
    }
    const navigatePriceRules=async(variables)=>{
        const result = await getPriceRules(appProps.apolloClient,variables)
        let rowsObj = []
        if(result.success) {
            setPageInfo(result.data.pageInfo)
            setTotalCount(result.data.pageInfo.totalCount?result.data.pageInfo.totalCount:0)
            if(result.data.edges.length>0) {
                for (let index = 0; index < result.data.edges.length; index++) {
                    const element = result.data.edges[index].node;
                    rowsObj.push(createData(element.code,element.status,0,element.startsAt,element.id))                                
                }
                setRows(rowsObj)
            }
        } else {
            setAlertModal({type:'Error',message:result.error.message})
        }
    }
    const handleFilter=(value)=>{
        try {
            navigatePriceRules({first:value})
        } catch (error) {
            setLoading(false)
            setAlertModal({type:'Error',message:"Something went wrong please try again later"})
        }
    }
    React.useEffect(async() => {
        try {
            navigatePriceRules({first})
        } catch (error) {
            setAlertModal({type:'Error',message:"Something went wrong please try again later"})
        }
    },[]);
    const visitPriceRule = (id)=>{
        history(`/discounts/${id}`)
    }
    return (
        <div>
        <Box>
            <SubHeader locationName={"Discounts"} actionBtn={"Create Discount"} actionBtnFnc={()=>history("/create-discount")} />
            <Box p={1}></Box>
            {rows && rows.length>0? 
            <Box container >
                <Box item>
                    {rows && <DataTable handleFilter={handleFilter} openPage={visitPriceRule} navigatePage={navigatePriceRules} first={first} totalCount={totalCount} tableHeaders={tableHeaders} rows={rows} pageInfo={pageInfo}/>}
                </Box>
            </Box>:
            <Box className={classes.container} >
                <Box className={classes.cardForm}>
                    <Box style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column", width:"100%"}}>
                        <img src={NoDiscount} alt="No order icon" />
                        <Typography variant="h5" style={{marginBottom:20}}>Manage discounts and promotions</Typography>
                        <Typography variant="body1" style={{marginBottom:20}}>Create discount codes that apply at checkout. You can also combine discounts with compare at prices.</Typography>
                        
                    </Box>
                </Box>
            </Box>}
            {alertModal && <AlertModal/>}
        </Box>
        </div>
    )
}

export default Discounts