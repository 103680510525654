import React,{useState,useContext,useEffect,Fragment} from 'react'
import { Button, Box, CircularProgress,Typography,TextField} from "@material-ui/core"
import SearchOutlined from '@material-ui/icons/SearchOutlined'
import { useStyles } from '../commonStyle'
import { useSelector} from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import {Context} from "../../context/AppContext"
import AlertModal from "../../components/AlertModal/AlertModal"
import SubHeader from "../../components/SubHeader/SubHeader"
import RightSide from './RightSide';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import {priceRuleCreate} from '../../APi/api';

function AddDiscount() {
    const classes = useStyles()
    const appProps = useSelector(state => state);
    

    /*const get_customers = async(query)=>{
        if(query) {
            const customersRes = await getCustomers(appProps.apolloClient,{query})
            console.log("customersRes",customersRes);
            if(customersRes.success) {
                setAllCustomers(customersRes.data.edges)
            } else {
                setAllCustomers([])
                showAlert("ERROR",customersRes.error.message)
            }
        } else {
            setAllCustomers([])
        }
    }*/
    
    const {alertModal, setAlertModal} = useContext(Context)
    const [codeType,setCodeType] = useState({
        value: 0,
        value_type:"PERCENTAGE"
    })
    const [isCreating, setCreating] = useState(false)
    const [minRequirement,setMinRequirement] = useState("NONE")
    const [start,setStart] = useState("2022-11-24T15:04")
    const [end,setEnd] = useState(null)
    const [minPurchaseAmount,setMinPurchaseAmount] = useState(0)
    const [minQuantity,setMinQuantity] = useState(0)
    const [selectedShipping,setSelectedShipping] = useState([])
    const [selectedCustomers,setSelectedCustomers] = useState([])
    const [allProvinces,setAllProvinces] = useState([])
    const [allCustomers,setAllCustomers] = useState([])
    const [shippingOption,setShippingOption] = useState("ALL")
    const [selectCustomer,setSelectCustomer] = useState("ALL_CUSTOMER")
    const [limitUsageNumber, setLimitUsageNumber] = React.useState(false);
    const [limitUsageNumberCustomer, setLimitUsageNumberCustomer] = React.useState(false);
    const [usageNumber,setUsageNumber] = useState(0)
    const [priceRuleDiscountCode,setPriceRuleDiscountCode]=useState({
        "code": ""
    })
    const handleChange = (target,value) => {
        console.log('test');
    };
    const showAlert = (type,message)=>{
        setAlertModal({type:type,message:message})
    }
    const handleCode = (target,value) => {
        setPriceRuleDiscountCode({ ...priceRuleDiscountCode, [target]: value });
    };
    
    const setSearchedProvince =(data)=>{
        let selectedShippings = selectedShipping
        if(selectedShipping.length>0) {
            let itempos = selectedShipping.map(function(x) {return x.id }).indexOf(data.id);
            if (itempos>-1) {
                alert("already added!")
            } else {
                selectedShippings.push(data)
            }
        } else {
            selectedShippings.push(data)
        }
        setSelectedShipping(selectedShippings)
    }
    const setSearchedCustomer =(data)=>{
        if(selectedCustomers.length>0) {
            let itempos = selectedCustomers.map(function(x) {return x.id }).indexOf(data.id);
            if (itempos>-1) {
                alert("already added!")
            } else {
                selectedCustomers.push(data)
            }
        } else {
            selectedCustomers.push(data)
        }
        setSelectedCustomers(selectedCustomers)
    }
    const add_discount =async()=>{
        let priceRule = {
            title: priceRuleDiscountCode.code,
            value:codeType,
            validityPeriod: {
                start,
                end,                
            },
            allocationLimit:1,
            target: codeType.value_type==="FREE_SHIPPING"?"SHIPPING_LINE":"LINE_ITEM",
            allocationMethod: codeType.value_type==="BXGY"?"EACH":"ACROSS",
            customerSelection: {
                forAllCustomers: selectCustomer==="ALL_CUSTOMER"?true:false,
                customerIdsToAdd: selectCustomer==="ALL_CUSTOMER"?[]:selectedCustomers.map((item)=>item.id)
            },
            usageLimit: parseInt(usageNumber),
            prerequisiteSubtotalRange:{
                greaterThan:minRequirement==="NONE"?0:minPurchaseAmount,
            },
            oncePerCustomer:limitUsageNumberCustomer
        }
        let variables = {
            priceRule,
            priceRuleDiscountCode
        }
        console.log(variables);
        const response = await priceRuleCreate(appProps.apolloClient,variables)
        console.log("respi", response)
        if(response.success) {
            showAlert("Success","Discount Created successfully")
        } else {
            showAlert("Error",response.error.message)
        }
    }
    useEffect(() => {
    },[appProps])
    return (
        <Box>
            <SubHeader locationName={"Create Discount"} />
            <div className={classes.mainContainer}>
                <Box className={classes.leftSide}>
                    <Box className={classes.cardForm}>
                        <Box pt={1}>
                            <Typography variant="h5">Discount code</Typography>
                            <TextField value={priceRuleDiscountCode.code} placeholder="e.g FASTLY40" onChange = {(event)=>handleCode('code',event.target.value)} fullWidth variant="outlined" size="small" />
                            <Typography variant="body2">Customers will enter this discount code at checkout.</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.cardForm}>
                        <Typography variant="h5">Product Statu {codeType.value_type}</Typography>
                        <Box p={1}></Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                labelId="select-outlined"
                                id="statu-select"
                                size="small"
                                value={codeType.value_type}
                                onChange={(event)=>setCodeType({ ...codeType, value_type: event.target.value })}
                            >
                            <MenuItem  value={"PERCENTAGE"}>Percentage</MenuItem>
                            <MenuItem value={"FIXED"}>Fixed</MenuItem>
                            <MenuItem  value={"FREE_SHIPPING"}>Free Shipping</MenuItem>
                            <MenuItem value={"BXGY"}>Buy X get Y</MenuItem>
                            </Select>
                        </FormControl>
                        {codeType.value_type === "PERCENTAGE" || codeType.value_type === "FIXED" ?
                        <Box pt={1}>
                            <Typography variant="h5">Value</Typography>
                            <TextField value={codeType.value} placeholder={""} onChange = {(event)=>setCodeType({ ...codeType, value: parseFloat(event.target.value) })} fullWidth variant="outlined" size="small" 
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{codeType.value_type==="PERCENTAGE"?"%":"$"}</InputAdornment>,
                            }}/>
                        </Box>:null}
                        {codeType.value_type === "FREE_SHIPPING" &&<Box pt={1}>
                            <Typography variant="h5">Provinces</Typography>
                            <FormControl variant="outlined" size="small"  className={classes.formControl}>
                                <Select
                                    labelId="select-outlined"
                                    id="shipping-select"
                                    size="small"
                                    value={shippingOption}
                                    onChange={(event)=>setShippingOption(event.target.value)}
                                >
                                    <MenuItem  value={"ALL"}>All Provinces</MenuItem>
                                    <MenuItem value={"FEW"}>Selected Provinces</MenuItem>
                                </Select>
                            </FormControl>
                            {shippingOption === "FEW" &&<Box pt={1}>
                                <div>
                                
                                {allProvinces && allProvinces.length > 0 && <div style={{position:"relative"}}>
                                    <div style={{position:"absolute",width:"100%",overflow:"auto",height:200,backgroundColor:"yellow",zIndex:1000}}>
                                        {allProvinces.map((item,index)=>
                                        <div style={{width:"100%",height:50,padding:10,display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                            <p>{item.node.wilaya_name + " "+item.node.wilaya_code}</p>
                                            <div>
                                                <p onClick={()=>setSearchedProvince(item.node)} style={{backgroundColor:"#f6f6f7",padding:5,borderRadius:12,fontWeight:'bold',cursor:"pointer"}}>Add</p>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>}
                                {selectedShipping && selectedShipping.map((item,index)=>
                                    <div style={{width:"100%",height:50,padding:10,display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <p>{item.wilaya_name + " "+item.wilaya_code}</p>
                                        <div>
                                            <p onClick={()=> setSelectedShipping(()=> selectedShipping.filter(el => el !== item))} style={{backgroundColor:"#f6f6f7",padding:5,borderRadius:12,fontWeight:'bold',cursor:"pointer"}}>X</p>
                                        </div>
                                    </div>)
                                }
                                </div>
                            </Box>}
                        </Box>}
                    </Box>
                    
                    <Box className={classes.cardForm}>
                        <Typography variant="h5">Minimum requirements</Typography>
                        <Box p={1}></Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                labelId="select-outlined"
                                id="requirement-select"
                                size="small"
                                value={minRequirement}
                                onChange={(event)=>setMinRequirement(event.target.value )}
                            >
                            <MenuItem  value={"NONE"}>None</MenuItem>
                            <MenuItem value={"MIN_PURCHASE_AMOUNT"}>Minimum purchase amount (BNB)</MenuItem>
                            <MenuItem  value={"MIN_QUANTITY"}>Minimum quantity of items</MenuItem>
                            </Select>
                        </FormControl>
                        {minRequirement === "MIN_PURCHASE_AMOUNT" &&
                        <Box p={2}>                            
                            <TextField value={minPurchaseAmount} placeholder="0" onChange = {(event)=>setMinPurchaseAmount(event.target.value)} variant="outlined" size="small" />
                            <Typography variant="body1">Applies to all products.</Typography>
                        </Box>
                        }
                        {minRequirement === "MIN_QUANTITY" &&
                        <Box p={2}>
                            <TextField value={minQuantity} placeholder="0" onChange = {(event)=>setMinQuantity(event.target.value)} variant="outlined" size="small" />
                            <Typography variant="body1">Applies to all products.</Typography>
                        </Box>
                        }
                    </Box>
                    <Box className={classes.cardForm}>
                        <Typography variant="h5">Customer eligibility</Typography>
                        <Box p={1}></Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                labelId="select-outlined"
                                id="requirement-select"
                                size="small"
                                value={selectCustomer}
                                onChange={(event)=>setSelectCustomer(event.target.value )}
                            >
                            <MenuItem  value={"ALL_CUSTOMER"}>Everyone</MenuItem>
                            <MenuItem  value={"SPECIFIC_CUSTOMER"}>Specific customers</MenuItem>
                            </Select>
                        </FormControl>
                        {selectCustomer === "SPECIFIC_CUSTOMER" &&
                        <Box p={2}>                            
                            <TextField
                                    id="search-customers"
                                    placeholder="Search Customers.."
                                    onChange = {(event)=>console.log(event.target.value)}
                                    InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlined/>
                                        </InputAdornment>
                                    ),
                                    }}
                                    fullWidth variant="outlined" size="small" 
                            />
                            {allCustomers && allCustomers.length > 0 && <div style={{position:"relative"}}>
                                    <div style={{position:"absolute",width:"100%",overflow:"auto",height:200,backgroundColor:"yellow",zIndex:1000}}>
                                        {allCustomers.map((item,index)=>
                                        <div style={{width:"100%",height:50,padding:10,display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                            <p>{item.node.displayName}</p>
                                            <div>
                                                <p onClick={()=>setSearchedCustomer(item.node)} style={{backgroundColor:"#f6f6f7",padding:5,borderRadius:12,fontWeight:'bold',cursor:"pointer"}}>Add</p>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>}
                                {selectedCustomers && selectedCustomers.map((item,index)=>
                                    <div style={{width:"100%",height:50,padding:10,display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <p>{item.displayName}</p>
                                        <div>
                                            <p onClick={()=> setSelectedCustomers(()=> selectedCustomers.filter(el => el !== item))} style={{backgroundColor:"#f6f6f7",padding:5,borderRadius:12,fontWeight:'bold',cursor:"pointer"}}>X</p>
                                        </div>
                                    </div>)
                                }
                        </Box>
                        }
                    </Box>
                    <Box className={classes.cardForm}>
                        <Typography variant="h5">Usage limits</Typography>
                        <div style={{display:"flex",alignItems:"center"}}>
                            <Checkbox
                                checked={limitUsageNumber}
                                onChange={(event)=>setLimitUsageNumber(event.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography variant="body1">Limit number of times this discount can be used in total</Typography>
                        </div>
                        {limitUsageNumber &&
                        <Box style={{marginLeft:40}}>
                            <TextField value={usageNumber} placeholder={"usage number"} onChange = {(event)=>setUsageNumber(event.target.value)} variant="outlined" size="small" />
                        </Box>}
                        <div style={{display:"flex",alignItems:"center"}}>
                            <Checkbox
                                checked={limitUsageNumberCustomer}
                                onChange={(event)=>setLimitUsageNumberCustomer(event.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography variant="body1">Limit to one use per customer</Typography>
                        </div>
                    </Box>
                    <Box className={classes.cardForm}>
                        <Typography variant="h5">Active dates</Typography>
                        <Typography variant="h6">Start Date</Typography>
                        <form className={classes.container} noValidate>
                            <TextField
                                InputProps={{disableUnderline : true}}
                                id="datetime-local"
                                type="datetime-local"
                                defaultValue={start}
                                onChange={(event) => setStart(event.target.value)}
                                fullWidth variant="outlined" size="small" 
                            />
                        </form>
                        <Typography variant="h6">End Date</Typography>
                        <form className={classes.datePikers} noValidate>
                            <TextField
                                InputProps={{disableUnderline : true}}
                                id="datetime-local"
                                type="datetime-local"
                                defaultValue={end}
                                onChange={(event) => setEnd(event.target.value)}
                                fullWidth variant="outlined" size="small" 
                            />
                        </form>
                    </Box>
                    <Box pt={2} style={{width:"50%",alignSelf:"center"}}>
                        <Button 
                            variant="contained" 
                            fullWidth 
                            color="secondary" 
                            disableElevation={true}
                            onClick={()=>add_discount()}
                        >
                            {isCreating?<CircularProgress size={25} />:"Submit"}                        
                        </Button>
                    </Box>
                </Box>
                <Box className={classes.rightSide}>
                    <RightSide variables={codeType} handleChange={handleChange} />
                </Box>
            </div>
            
            {alertModal && <AlertModal/>}
        </Box>
    )
}

export default AddDiscount
