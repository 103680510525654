import {
    ADMIN_LOGIN,
    DISCOUNT_CODE_CREATE,
    DISCOUNT_CODE_UPDATE,
    EMAIL_SEND,
} from "../Graphql/Mutations"
import {
    GET_ADMIN_INFO,
    GET_PRICE_RULES,
    GET_CUSTOMERS,
} from "../Graphql/Queries"
export function adminLogin(apollo,variables) {
    return apollo.mutate({
        mutation: ADMIN_LOGIN,
        variables: variables
    })
    .then((response) => {
        console.log("response",response);
        if (response.data.loginAdmin.success) {
            return({success:true,data:response.data.loginAdmin.token})
        } else {
            return({success:false,error:response.data.loginAdmin.error})
        }
    })
    .catch((error)=>{
        console.log(error);
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please contact your admin"
            }
        })
    })
}

export function getAdminInfo(apollo) {
    return apollo.query({
        query: GET_ADMIN_INFO
    })
    .then((response) => {
        if (response.data.meAdmin.success) {
            return({success:true,data:response.data.meAdmin.information})
        } else {
            return({success:false,error:response.data.meAdmin.error})
        }
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please contact your admin"
            }
        })
    })
}




export function priceRuleCreate(apollo,variables) {
    return apollo.mutate({
        mutation: DISCOUNT_CODE_CREATE,
        variables: variables
    })
    .then((response) => {
        console.log("response",response);
        if (response.data.priceRuleCreate.success) {
            return({success:true,data:response.data.priceRuleCreate.priceRule})
        } else {
            return({success:false,error:response.data.priceRuleCreate.error})
        }
    })
    .catch((error)=>{
        console.log("error var",JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please contact your admin"
            }
        })
    })
}

export function getPriceRules(apollo,variables={}) {
    return apollo.query({
        query: GET_PRICE_RULES,
        variables,
        fetchPolicy:'network-only'
    })
    .then((response) => {
        if (response.data.priceRules.success) {
            return({success:true,data:response.data.priceRules})
        } else {
            return({success:false,error:response.data.priceRules.error})
        }
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please contact your admin"
            }
        })
    })
}

export function priceRuleUpdate(apollo,variables) {
    return apollo.mutate({
        mutation: DISCOUNT_CODE_UPDATE,
        variables: variables
    })
    .then((response) => {
        console.log("response",response);
        if (response.data.priceRulesUpdate.success) {
            return({success:true,data:response.data.priceRulesUpdate.priceRule})
        } else {
            return({success:false,error:response.data.priceRulesUpdate.error})
        }
    })
    .catch((error)=>{
        console.log("error var",JSON.stringify(error))
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please contact your admin"
            }
        })
    })
}

export function getCustomers(apollo,variables={}) {
    return apollo.query({
        query: GET_CUSTOMERS,
        variables,
        fetchPolicy:'network-only'
    })
    .then((response) => {
        if (response.data.customers.success) {
            return({success:true,data:response.data.customers})
        } else {
            return({success:false,error:response.data.customers.error})
        }
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please contact your admin"
            }
        })
    })
}

export function emailSend(apollo,variables) {
    return apollo.mutate({
        mutation: EMAIL_SEND,
        variables: variables
    })
    .then((response) => {
        console.log("response",response);
        if (response.data.emailSend.success) {
            return({success:true})
        } else {
            return({success:false,error:response.data.emailSend.error})
        }
    })
    .catch((error)=>{
        console.log("error var",JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please contact your admin"
            }
        })
    })
}