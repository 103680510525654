import React from 'react';
import './tile.css';

function Tiles(props) {
    const { title, value } = props;
    return (
        <div className="tile">
            <div className="head">
                <p>{title}</p>
            </div>
            <div className="body">
                <h5>{value}</h5>
            </div>
        </div>
    )
}

export default Tiles
