export default {
  MuiButton : {
    root : {
      borderRadius : 2
    }
  },
  MuiOutlinedInput : {
    root : {
      borderRadius : 2,
      "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
        /* border: "2px solid", */
        borderColor: "#AAA"
      },
    },
  },
  /* MuiInputLabel : {
    animated : {
      zIndex : -1
    }
  }, */
  
  MuiInput : {
    underline : {
      border : "none",
    }
  },
  MuiAccordion : {
    root : {
      borderRadius : 2,
      border : "1px solid #ccc"
    },
    rounded : {
      borderRadius : 2
    }
  },
  MuiCssBaseline: {
      '@global': {
        html: {
          scrollBehavior: 'smooth',
        },
      },
    },
}