import React,{useState,useEffect} from 'react'
import { Grid, Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, makeStyles} from "@material-ui/core"
import FilterBar from "./filterBar/FilterBar"
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles(theme=> ({
    container : {
        padding : 16,
        backgroundColor : "#FFF"
    },
    tablecontainer : {
        height : "100%",
        boxShadow : "4px 4px 20px #ccc",
        borderRadius : 2,
    },
    tableHead : {
        borderTop : "2px solid #8f58fd",
        borderBottom : "2px solid #8f58fd",
    },
    paginationArrows : {
        borderRadius : 2,
        backgroundColor : "#6145ef",
        marginLeft : 24,
        cursor : "pointer",
        "& div" : {
            width : 25,
            height : 25,
            display :"flex",
            justifyContent : "center",
            alignItems : "center",
        },
        "& .MuiSvgIcon-root" : {
            fontSize : 12,
            color : "#fff"
        }
    },
    counter : {
        backgroundColor : "rgb(249, 249, 249)",
        color : "#6145ef",
    }
}))

function DataTable({tableHeaders, rows,totalCount,pageInfo,navigatePage,first,openPage,handleFilter}) {
    const classes = useStyles()
    const [tableRows,setTableRows] = useState(null)
    const [filterUp,setFilterUp] = React.useState(false)
    const [currentPage,setCurrentPage] = useState(1)
    const [pagPos,setPagePos]= useState(1)
    useEffect(() => {
        setTableRows(rows)
    },[rows]);
    const filterOffer =(filter)=>{
        try {
            let newArr = Object.values(rows)
            newArr.sort(function (a, b) {
                let keya =Object.keys(a)[filter];
                let keyb =Object.keys(b)[filter];
                var elemA = a[keya].toString().toUpperCase();
                var elemB = b[keyb].toString().toUpperCase();
                setFilterUp(!filterUp)
                if(filterUp) {
                    return elemA.localeCompare(elemB);
                } else {
                    return elemB.localeCompare(elemA);
                }
                
            })
            setTableRows(newArr)
        } catch (e){
            console.log(e);
        }
    }
    const tableHandleFilter=(value)=>{
        console.log("value",value);
        handleFilter(value)
    }
    const searchElement=(keyword)=>{
        
        if(rows) {
            if(keyword ==="") {
                setTableRows(rows)
            } else {
                let searchedObj = []
                for (const key in rows) {
                    if (Object.hasOwnProperty.call(rows, key)) {
                        const element = rows[key];
                        for (const small_key in element) {
                            
                            if (Object.hasOwnProperty.call(element, small_key)) {
                                const small_element = element[small_key];
                                if(typeof small_element ==="string") {
                                    if(small_element && small_element.toLowerCase().includes(keyword.toLowerCase())) {
                                        const index = searchedObj.map(function(x) {return x.id; }).indexOf(element.id);
                                        if (index === -1) {
                                            searchedObj.push(element)
                                        }
                                    }
                                } else if(typeof small_element ==="number") {
                                    if(small_element && small_element.toString().toLowerCase().includes(keyword.toLowerCase())) {
                                        const index = searchedObj.map(function(x) {return x.id; }).indexOf(element.id);
                                        if (index === -1) {
                                            searchedObj.push(element)
                                        }
                                    }
                                }
                                
                            }
                        }
                        
                        
                    }
                }
                setTableRows(searchedObj)
            }
            
        }
    }
    const navigate=(variables,action="next")=>{
        if(action==="next"){
            setCurrentPage(currentPage+1)
            setPagePos(pagPos+first)
        } else {
            console.log("currentPage-1",currentPage-1);
            setCurrentPage(currentPage-1)
            setPagePos(pagPos-first)
        }
        navigatePage(variables)
    }

    return (
        <Grid className={classes.tablecontainer} >
            <FilterBar searchElement={searchElement} handleFilter={tableHandleFilter}/>
            <TableContainer className={classes.container} elevation={0} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                <TableRow className={classes.tableHead}>
                    {
                        tableHeaders && tableHeaders.map((header, i)=> (
                            <TableCell key={i}>
                                <Box display="flex" p={0} justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle1" color="primary">{header}</Typography>
                                {header!=="Action"&& header!=="Statu" && <ImportExportIcon fontSize="small" onClick={()=>filterOffer(i)}/>}
                                </Box>
                            </TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                {tableRows && tableRows.map((row, i) => (
                    <TableRow key={i} onClick={()=>openPage(row.id)}>
                    {
                        Object.keys(row).map((item, i)=> {
                            if(item !== "id" ){
                                return(
                                    <TableCell key={i}>{row[item]}</TableCell>
                                )
                            }
                        })
                    }
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            <Box bgcolor="#FFF" pr={3} pb={3} pt={3} display="flex" alignItems="center" justifyContent="flex-end">
                <Typography variant="body2">{pagPos}-{tableRows && pagPos+tableRows.length - 1} of {totalCount}</Typography>
                <Box display="flex" className={classes.paginationArrows}>
                    {pageInfo && pageInfo.hasPreviousPage &&<Box onClick={()=>navigate({first,before:pageInfo.startCursor},"previous")}>
                        <ArrowBackIosIcon fontSize="small" />
                    </Box>}
                    <Box className={classes.counter} >
                        <Typography variant="body2">{currentPage}</Typography>
                    </Box>
                    {pageInfo && pageInfo.hasNextPage &&
                    <Box onClick={()=>navigate({first,after:pageInfo.endCursor},"next")}>
                        <ArrowForwardIosIcon fontSize="small" />
                    </Box>
                    }
                </Box>
            </Box>
        </Grid>
    )
}

export default DataTable