import React,{useState,useContext} from 'react'
import { Box } from "@material-ui/core"
import { useStyles } from '../styles'
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable/DataTable"
import { useSelector} from "react-redux";
import {Context} from "../../context/AppContext"
import AlertModal from "../../components/AlertModal/AlertModal"
import SubHeader from "../../components/SubHeader/SubHeader"
import {getCustomers} from '../../APi/api'
function Customers() {
    const classes = useStyles()
    const navigate = useNavigate()
    const {alertModal, setAlertModal} = useContext(Context)
    const appProps = useSelector(state => state);
    const {userInfo} = appProps
    let tableHeaders = ["User Name","Email","Date"]
    const [rows,setRows] = useState(null)
    const [pageInfo,setPageInfo] = useState({})
    const [totalCount,setTotalCount] = useState(0)
    const [first,setFirst] = useState(10)
    const [isLoading,setLoading] = useState(false)
    function createData(displayName,email,date,id){
        return { displayName,email,date,id}
    }
    const navigateCustomers=async(variables)=>{
        const result = await getCustomers(appProps.apolloClient,variables)
        console.log('result',result);
        let rowsObj = []
        if(result.success) {
            console.log(result.data.pageInfo);
            setPageInfo(result.data.pageInfo)
            setTotalCount(result.data.pageInfo.totalCount)
            if(result.data.edges.length>0) {
                for (let index = 0; index < result.data.edges.length; index++) {
                    const element = result.data.edges[index].node;
                    rowsObj.push(createData(element.info.userName,element.info.email, element.info.created_at,element.info.id))                                
                }
                setRows(rowsObj)
                console.log("rowsObj",rowsObj);
            }
        } else {
            setAlertModal({type:'Error',message:result.error.message})
        }
    }
    const handleFilter=(value)=>{
        try {
            navigateCustomers({first:value})
        } catch (error) {
            setLoading(false)
            setAlertModal({type:'Error',message:"Something went wrong please try again later"})
        }
    }
    React.useEffect(async() => {
        try {
            navigateCustomers({first})
        } catch (error) {
            console.log("error",error);
            setAlertModal({type:'Error',message:"Something went wrong please try again later"})
        }
        
        
    },[]);
    const visitCustomer = (id)=>{
        console.log("id",id);
        navigate(`/customers/${id}`)
    }
    return (
        <Box>
            <SubHeader locationName={"Customers "+totalCount} actionBtn={"Add Customer"} actionBtnFnc={()=>navigate("/add-new-customer")} />
            <Box p={1}></Box>
            <Box container >
                <Box item>
                    {rows && <DataTable handleFilter={handleFilter} openPage={visitCustomer} navigatePage={navigateCustomers} first={first} totalCount={totalCount} tableHeaders={tableHeaders} rows={rows} pageInfo={pageInfo}/>}
                </Box>
            </Box>
            {alertModal && <AlertModal/>}
        </Box>
    )
}

export default Customers