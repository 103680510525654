import React from 'react'
import { Box} from '@material-ui/core'
import SubHeader from '../../components/SubHeader/SubHeader'
import './dashboard.css'
import Tiles from '../../components/Tiles/Tiles';
function Dashboard() {
    
    return (
        <Box>
        <SubHeader locationName="Dashboard"/>
        
          <div className="container-fluid mt-4 p-0">
            <Box display="flex" justifyContent="space-between">
                <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
                    <Tiles title="Total Orders" value={0} />
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
                    <Tiles title="Total Customers" value={10} />
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
                    <Tiles title="Total Partners" value={5} />
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
                    <Tiles title="Total Discounts" value={0} />
                </div>
            </Box>
          </div>
        </Box>
    

        )
}

export default Dashboard;
