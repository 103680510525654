import React, {useState} from 'react'
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme)=> ({
    filterBar : {
        display : "flex",
        alignItems : "center",
        justifyContent : "space-between",
        backgroundColor : "#FFF",
        padding : "32px 16px 0px 16px",
        borderRadius : 4,
        "& .MuiInputBase-root" : {
            backgroundColor : "#fff",
        }
    },
    formControl: {
        minWidth: 90,
        backgroundColor : "#fff",
        "& .MuiSelect-root" : {
            color : "#BBB"
        }
    },
}))

function FilterBar({searchElement,handleFilter}) {
    const classes = useStyles()
    const [entries, setEntries] = useState(10)

    const handleChange = (event) => {
        setEntries(event.target.value);
        handleFilter(event.target.value)
    };

    return (
        <Box className={classes.filterBar}>
            <FormControl size="small" variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">show</InputLabel>
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={entries}
                onChange={handleChange}
                label="show"
                >
                    <MenuItem value={10}>10 entries</MenuItem>
                    <MenuItem value={20}>20 entries</MenuItem>
                    <MenuItem value={30}>30 entries</MenuItem>
                </Select>
            </FormControl>
            <TextField size="small" id="outlined-basic" label="search" variant="outlined" onChange={(event)=>searchElement(event.target.value)} />
        </Box>
    )
}

export default FilterBar
