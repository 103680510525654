import { makeStyles } from "@material-ui/core"
export const useStyles = makeStyles((theme) => ({
    container : {
        paddingRight : 24
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        backgroundColor : "#f6f6f7"
    },
    mainView : {
        backgroundColor : "#fff",
        borderRadius : 4,
        marginTop : 16,
        "& .MuiAvatar-root" : {
            width : 60,
            height : 60,
            backgroundColor : "#6145ef"
        }
    },
    addProductView : {
        boxShadow : "4px 4px 20px #ccc",
        minHeight : "70vh"
    },
    header : {
        display : "flex",
        borderRadius : 2,
        backgroundColor : theme.palette.secondary.main,
        padding : "8px 16px",
        margin : "8px 16px",
        color : "#fff",
        "& .MuiSvgIcon-root" : {
            marginRight : 8,
            color : "#fff"
        },
        "& .MuiTypography-root" :{
            marginTop : 0
        }
    },
    datePikers : {
        display : "flex",
        '& .MuiInput-underline:before': {
            borderBottom : "none",
          },
        '& .MuiInput-underline:after': {
            borderBottom : "none",
            "&:hover" : {border : "none"}
          },
        "& .MuiFormControl-root" : {
            marginTop : 0,
            marginRight : 16
        },
        "& > .MuiTypography-root" : {
            marginTop : 16
        },
        "& .MuiInputBase-root" : {
            paddingLeft : 12,
            border : "1px solid #ccc",
            borderRadius : 2,
            padding : 4,
        },
        
    },
    modalContainer : {
        position : "fixed", 
        height : "100vh",
         width : "100vw", 
        top : 0, 
        left : 0, 
        right : 0, 
        backgroundColor : "rgba(0,0,0,0.5)",
        zIndex : 5000
    },
    compaigneModal : {
        zIndex : 5001,
        "& .MuiInputBase-root" : {
            marginBottom : 16
        },
        "& .MuiSvgIcon-root" : {
            cursor : "pointer"
        },
        backgroundColor : "#fff",
        padding : 24,
        position : "fixed",
        /* boxShadow : "1px 1px 15px #ccc", */
        top : "50%",
        left : "50%",
        transform : "translate(-50%, -50%)"
    },
    dataBtns : {
        display : "flex",
        "& .MuiBox-root" :{
            borderRadius : 8,
            padding : "2px 6px",
            cursor : "pointer",
            marginRight : "2px"
        },
        "& .MuiTypography-root" : {
            fontSize : "10px",
            color : "#fff",
            fontWeight : "700"
        }
    },
    mediaTeacherWaiting: {
        width: 151,
        height:151,
    },
}))