import { gql } from '@apollo/client';
export const GET_ADMIN_INFO = gql`
  query MeAdmin{
    meAdmin{
      information{
        pk
        admin_id
        full_name
        email
        role
        level
        active
      }
      success
      error{
        code
        message
      }
    }
  }
`;
export const GET_PRICE_RULES = gql`
  query priceRules($first: Int, $after: String, $last: Int, $before: String, $reverse: Boolean, $sortKey: CustomerSortKeys, $query: String, $id: ID){
    priceRules(first: $first, after: $after, last: $last, before: $before, reverse: $reverse, sortKey: $sortKey, query: $query, id: $id){
      success
      error{
        code
        message
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
        totalCount
      }
      edges{
        node{
          id
          createdAt
          usageLimit
          allocationLimit
          allocationMethod
          oncePerCustomer
          endsAt
          status
          startsAt
          code
          target
          value{
            type
            value
          }
          prerequisiteSubtotalRange{
            greaterThan
          }
        }
        cursor
      }
    }
  }
`
export const GET_CUSTOMERS = gql`
query customers($first: Int, $after: String, $last: Int, $before: String, $reverse: Boolean, $sortKey: CustomerSortKeys, $query: String, $id: ID) {
  customers(first: $first, after: $after, last: $last, before: $before, reverse: $reverse, sortKey: $sortKey, query: $query, id: $id) {
    success
    error {
      code
      message
    }
    edges {
      node {
        info{
          id
          email
          userName
          created_at
        }
        user_transactions{
          id
          userId
          sender
          receiver
          created_at
          amount
        }
        user_subscription{
          cursor
          node{
            id
            userId
            node
            sub_type
            sub_date
            created_at
            ws
            rpc
            status
          }
        }
        user_bot_subscription {
          cursor
          node {
            id
            userId
            sub_type
            sub_date
            created_at
            status
            licenseKey
          }
        }
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
      totalCount
    }
  }
}
`;
