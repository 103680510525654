import React,{useState,useContext} from 'react'
import { Typography, TextField, Button, Box, Checkbox, Avatar,CircularProgress } from "@material-ui/core"
import { useStyles } from './Style'
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { ApolloClient, InMemoryCache,HttpLink } from '@apollo/client'
import {tokenizeEmail} from '../../Utils/HelpfullFunction'
import { useDispatch } from "react-redux";
import {GraphHost} from "../../Config/Constants"
import {Context} from "../../context/AppContext"
import {adminLogin,getAdminInfo} from '../../APi/api'
import AlertModal from "../../components/AlertModal/AlertModal"
function Login() {
    const classes = useStyles()
    const navigate = useNavigate()
    const {alertModal, setAlertModal} = useContext(Context)
    const [visible, setVisible] = React.useState(false)
    const [isLoging, setLoging] = useState(false)
    const [checkingAuth, setCheckingAuth] = useState(true)
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const dispatch = useDispatch();
    const _setUserCredential=async(userCredential)=>{
        const client = new ApolloClient({
            cache: new InMemoryCache({
                addTypename: false
            }),
            uri: GraphHost,
            headers: {
                Authorization: userCredential.token,
            },
        })
        const act = { type: "SET_APOLLO", value: client }
        dispatch(act)
        localStorage.setItem('userCredential', JSON.stringify(userCredential));
        const result = await getAdminInfo(client)
        if (result.success) {
            console.log(result);
            const act = { type: "SET_APOLLO", value: client }
            dispatch(act)
            dispatch({ type: "USER_INFORMATION", value: result.data })
            dispatch({ type: "USER_CREDENTIAL", value: userCredential })
            navigate("/")
            
        } else {
            setAlertModal({type:"Alert",message:result.error.message})
            setLoging(false)
        }
        
    }
    const requestUserLogin = async ()=> {
        setLoging(true)
        const client = new ApolloClient({
            cache: new InMemoryCache({
                addTypename: false
            }),
            link: new HttpLink({
                uri: GraphHost,
            })
        })
        if(email !== "" && password!==""){
            if(client) {
                let tokemail = tokenizeEmail(email)
                let variables = {email:tokemail,password}
                const result = await adminLogin(client,variables)
                if (result.success) {
                    const userCredential = {
                        "token":result.data,
                    }
                    _setUserCredential(userCredential)
                    
                } else {
                    setLoging(false)
                    setAlertModal({type:"Alert",message:result.error.message})
                }
            } else {
                setLoging(false)
                setAlertModal({type:"Error",message:"Something went wrong please try again later"})
            }
        } else {
            setLoging(false)
            setAlertModal({type:"Alert",message:"all fields are required"})
        }
        
    }
    const checkAtStart = async()=> {
        const userCredentialObj = localStorage.getItem('userCredential');
        if (userCredentialObj !== null) {
            const userCredential = JSON.parse(userCredentialObj);
            const client = new ApolloClient({
                cache: new InMemoryCache({
                    addTypename: false
                }),
                uri: GraphHost,
                headers: {
                    Authorization: userCredential.token,
                },
            })
            const result = await getAdminInfo(client)
            if (result.success) {
                const act = { type: "SET_APOLLO", value: client }
                dispatch(act)
                console.log("result.data",result.data);
                dispatch({ type: "USER_INFORMATION", value: result.data })
                dispatch({ type: "USER_CREDENTIAL", value: userCredential })
                navigate("/")
            } else {
                setCheckingAuth(false)
                setAlertModal({type:"Alert",message:result.error.message})
            }
        } else {
            setCheckingAuth(false)
        }
    }
    React.useEffect(() => {
        checkAtStart()
    },[]);

    return (
        <Box className={classes.container}>
            {checkingAuth ? <CircularProgress color="primary" />:
            <Box className={classes.loginForm}>
                <Box pb={5} display="flex" flexDirection="column" alignItems="center">
                    <Avatar>A</Avatar>
                    <Typography variant="body2">Enter your email address and password to access admin panel</Typography>
                </Box>
                <Box pt={5}>
                    <Typography variant="body2">Email address</Typography>
                    <TextField onChange = {(event)=>setEmail(event.target.value)} fullWidth variant="outlined" size="small" />
                    <Box p={1} />
                    <Typography variant="body2">Password</Typography>
                    <Box display="flex" className={classes.passInput}>
                        <TextField onChange = {(event)=>setPassword(event.target.value)} type={ !visible ? "password" : "text"} fullWidth variant="outlined" size="small" />
                        <Box onClick={()=> setVisible(!visible)} >
                            {
                                visible ? 
                                <VisibilityIcon fontSize="small" /> :
                                <VisibilityOffIcon fontSize="small" />
                            }
                        </Box>
                    </Box>
                    <Box pt={1} display="flex" alignItems="center" >
                        <Checkbox defaultChecked={true} />
                        <Typography variant="subtitle2"> Remember me</Typography>
                    </Box>
                </Box>


                <Box pt={2}>
                    
                        <Button 
                        variant="contained" 
                        fullWidth 
                        color="secondary" 
                        disableElevation={true}
                        onClick={()=>requestUserLogin()}
                        >
                            {isLoging?<CircularProgress size={25} />:"Log in"}                        
                        </Button>
                    
                </Box>

            </Box>}

            {alertModal && <AlertModal/>}
        </Box>
    )
}

export default Login
