import React,{useState} from 'react' 
import { Typography, TextField, Box} from "@material-ui/core"
import { useStyles } from '../commonStyle'
import Btn from '../../components/Btn/Btn'
import NoOrder from '../../assets/icons/order.svg';
function Information(props) {
    const classes = useStyles()
    const {variables} = props
    const [visible, setVisible] = useState(false)
    const [isCreating,setCreating] = useState(false)
    const addOrder =(msg)=> {
        alert(msg)
    }
    return ( 
        <Box className={classes.container}>
            <Box className={classes.cardForm}>
                <Typography variant="h5">{variables.info.userName}</Typography>
                {/*<Typography variant="h6">{variables.locale + ", "+variables.province}</Typography>*/}
                <Typography variant="h6">Customer since {variables.info.created_at}</Typography>
            </Box>
            <Box className={classes.cardForm}>
                <Typography variant="h5">Last Order Placed</Typography>
                <Box style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column", width:"100%"}}>
                    <img src={NoOrder} alt="No order icon" />
                    <Typography variant="h6" style={{marginBottom:20}}>This customer hasn’t placed any orders.</Typography>
                </Box>
            </Box>
        </Box>
         
    )
} 
export default Information