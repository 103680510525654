import React,{useState,useContext} from 'react' 
import { Typography, TextField, Box,Button} from "@material-ui/core"
import { useStyles } from '../commonStyle'
import {Context} from "../../context/AppContext"
import AlertModal from "../../components/AlertModal/AlertModal"
import { useSelector} from "react-redux";
function OverView(props) {
    const classes = useStyles()
    const {variables} = props
    const {alertModal, setAlertModal} = useContext(Context)
    const appProps = useSelector(state => state);
    const infoRef = React.createRef();
    const addressRef = React.createRef();
    const [address,setAdress] = useState(variables.addresse)
    const [info,setInfo] = useState(variables)
    const [newVariables,setNewVariables] = useState({
        id:props.id
    })
    const handleChangeInfo = (target,value) => {
        setInfo({ ...info, [target]: value });
        setNewVariables({ ...newVariables, [target]: value });
        
        console.log("newv",newVariables);
    };
    const showAlert = (type,message)=>{
        setAlertModal({type:type,message:message})
    }
    return ( 
        <Box className={classes.container}>
            <Box className={classes.cardForm}>
                <div style={{borderBottom:"2px solid #f6f6f7", paddingBottom:10}}>
                    <div style={{display:"flex", justifyContent:'space-between'}}>
                        <Typography variant="h5">Customer overview</Typography>
                        <Typography variant="h5" style={{color:"blue", cursor:"pointer"}} onClick={()=>infoRef.current.toggle()}>Edit</Typography>
                    </div>
                    <div>
                        <Typography variant="h6">{variables.info.email}</Typography>
                    </div>
                </div>
            </Box>
            {alertModal && <AlertModal/>}
        </Box>
         
    )
} 
export default OverView