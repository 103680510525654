import React,{useState,useContext,useEffect,Fragment} from 'react'
import { Button, Box, CircularProgress,Typography,TextField} from "@material-ui/core"
import { useParams } from 'react-router-dom';
import { useStyles } from '../commonStyle'
import { useSelector} from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import {Context} from "../../context/AppContext"
import AlertModal from "../../components/AlertModal/AlertModal"
import SubHeader from "../../components/SubHeader/SubHeader"
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import {priceRuleUpdate,getPriceRules} from '../../APi/api';
import {formatDate} from '../../Utils/HelpfullFunction'
function Discount(props) {
    console.log("props",props);
    const classes = useStyles()
    const appProps = useSelector(state => state);
    const {id} = useParams()
    console.log("id",id);
    const [newVariables,setNewVariables] = useState({
        id:id
    })
    const [variables,setVariables] = useState({
        minRequirement:"NONE",
        start:"",
        end:"",
        minPurchaseAmount:0,
        minQuantity:0,
        selectedShipping:[],
        selectedCustomers:[],
        shippingOption:"ALL",
        selectCustomer:"ALL_CUSTOMER",
        limitUsageNumber:false,
        limitUsageNumberCustomer:false,
        usageNumber:0,
        code: "",
        value_type:"PERCENTAGE",
        value:0
    })
    const handleChange = (target,value) => {
        setVariables({ ...variables, [target]: value });
        if(target ==="limitUsageNumber") {
            return
        } else if(target === "usageNumber") {
            setNewVariables({ ...newVariables, usageLimit: parseInt(value) });
        }else if(target ==="selectedCustomers") {
            setNewVariables({ ...newVariables, customerSelection: {
                forAllCustomers: variables.selectCustomer==="ALL_CUSTOMER"?true:false,
                customerIdsToAdd: value.map((item)=>item.id)
            } });
        } else if(target==="limitUsageNumberCustomer"){
            setNewVariables({ ...newVariables, oncePerCustomer: value });
        } else if(target ==="selectedShipping"){
            setNewVariables({ ...newVariables, shippingEntitlements: {
                targetAllShippingLines: variables.shippingOption==="ALL"?true:false,
                provinces: value.map((item)=>item.id)
            } });

        } else if(target ==="start"){
            setNewVariables({ ...newVariables, validityPeriod: {
                start:value,
                end:variables.end,                
            }});
        }else if(target ==="end"){
            setNewVariables({ ...newVariables, validityPeriod: {
                start:variables.start,
                end:value,                
            }});
        }else if(target==="minPurchaseAmount"){
            setNewVariables({ ...newVariables, prerequisiteSubtotalRange: {
                greaterThan:value               
            }});
        } else if(target==="value") {
            setNewVariables({ ...newVariables, value: {
                value: value,
                value_type:variables.value_type
            }});
            
        }else if (target==="value_type"){
            setNewVariables({ ...newVariables, value: {
                value: variables.value,
                value_type:value
            }});
        } else {
            setNewVariables({ ...newVariables, [target]: value });
        }
        console.log("newVariables",newVariables);
    };
    const [loading,setLoading] = useState(false)
    
    const fetchPriceRules=async()=>{
        const result = await getPriceRules(appProps.apolloClient,{id:id})
        if(result.success) {
            
            const discountData = result.data.edges[0].node
            setMinRequirement(discountData.prerequisiteSubtotalRange.greaterThan>0?"MIN_PURCHASE_AMOUNT":"NONE")
            setMinPurchaseAmount(discountData.prerequisiteSubtotalRange.greaterThan)
            setVariables({
                minRequirement:discountData.prerequisiteSubtotalRange.greaterThan>0?"MIN_PURCHASE_AMOUNT":"NONE",
                start:formatDate(discountData.startsAt),
                end:formatDate(discountData.endsAt),
                minPurchaseAmount:discountData.prerequisiteSubtotalRange.greaterThan,
                minQuantity:0,
                limitUsageNumber:discountData.usageLimit>0?true:false,
                limitUsageNumberCustomer:discountData.oncePerCustomer>0?true:false,
                usageNumber:discountData.usageLimit,
                code: discountData.code,
                value_type:discountData.value.type,
                value:discountData.value.value
            })
            setLoading(false)
        } else {
            setAlertModal({type:'Error',message:result.error.message})
            setLoading(false)
        }
    }
    const {alertModal, setAlertModal} = useContext(Context)
    const [codeType,setCodeType] = useState({
        value: 0,
        value_type:"PERCENTAGE"
    })
    const [isCreating, setCreating] = useState(false)
    const [minRequirement,setMinRequirement] = useState("NONE")
    const [minPurchaseAmount,setMinPurchaseAmount] = useState(0)
    const [minQuantity,setMinQuantity] = useState(0)
    const [selectedShipping,setSelectedShipping] = useState([])
    const [allProvinces,setAllProvinces] = useState([])
    const [allCustomers,setAllCustomers] = useState([])
    const showAlert = (type,message)=>{
        setAlertModal({type:type,message:message})
    }
    const dicount_update =async()=>{
        console.log("newVariables",newVariables);
        const response = await priceRuleUpdate(appProps.apolloClient,{priceRule:newVariables})
        console.log("respi", response)
        if(response.success) {
            showAlert("Success","Discount Updated successfully")
        } else {
            showAlert("Error",response.error.message)
        }
    }
    const setSearchedProvince =(data)=>{
        let selectedShippings = variables.selectedShipping
        if(selectedShippings.length>0) {
            let itempos = selectedShippings.map(function(x) {return x.id }).indexOf(data.id);
            if (itempos>-1) {
                alert("already added!")
            } else {
                selectedShippings.push(data)
            }
        } else {
            selectedShippings.push(data)
        }
        handleChange("selectedShipping",selectedShippings)
    }
    const removeCustomer =(item)=>{
        const result = variables.selectedCustomers.filter(el => el !== item)
        handleChange("selectedCustomers",result)
    }
    const removeProvince =(item)=>{
        const result = variables.selectedShipping.filter(el => el !== item)
        handleChange("selectedShipping",result)
    }
    const setSearchedCustomer =(data)=>{
        let selectedCustomer = variables.selectedCustomers
        if(selectedCustomer.length>0) {
            let itempos = selectedCustomer.map(function(x) {return x.id }).indexOf(data.id);
            if (itempos>-1) {
                alert("already added!")
            } else {
                selectedCustomer.push(data)
            }
        } else {
            selectedCustomer.push(data)
        }
        handleChange("selectedCustomers",selectedCustomer)
    }
    useEffect(async() => {
        try {
            fetchPriceRules()
        } catch (error) {
            console.log("error",error);
            setAlertModal({type:'Error',message:"Something went wrong please try again later"})
        }        
    },[]);
    return (
        <Box>
            <SubHeader locationName={"Create Discount"} />
            <div className={classes.mainContainer}>
                <Box className={classes.leftSide}>
                    <Box className={classes.cardForm}>
                        <Box pt={1}>
                            <Typography variant="h5">Discount code</Typography>
                            <TextField value={variables.code} placeholder="e.g FASTLY40" onChange = {(event)=>handleChange('code',event.target.value)} fullWidth variant="outlined" size="small" />
                            <Typography variant="body2">Customers will enter this discount code at checkout.</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.cardForm}>
                        <Typography variant="h5">Product Statu {variables.value_type}</Typography>
                        <Box p={1}></Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                labelId="select-outlined"
                                id="statu-select"
                                size="small"
                                value={variables.value_type}
                                onChange={(event)=>handleChange('value_type', event.target.value)}
                            >
                            <MenuItem  value={"PERCENTAGE"}>Percentage</MenuItem>
                            <MenuItem value={"FIXED"}>Fixed</MenuItem>
                            <MenuItem  value={"FREE_SHIPPING"}>Free Shipping</MenuItem>
                            <MenuItem value={"BXGY"}>Buy X get Y</MenuItem>
                            </Select>
                        </FormControl>
                        {variables.value_type === "PERCENTAGE" || variables.value_type === "FIXED" ?
                        <Box pt={1}>
                            <Typography variant="h5">Value</Typography>
                            <TextField value={variables.value} placeholder={""} onChange = {(event)=>handleChange('value', parseFloat(event.target.value))} fullWidth variant="outlined" size="small" 
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{variables.value_type==="PERCENTAGE"?"%":"$"}</InputAdornment>,
                            }}/>
                        </Box>:null}
                        {variables.value_type === "FREE_SHIPPING" &&<Box pt={1}>
                            <Typography variant="h5">Provinces</Typography>
                            <FormControl variant="outlined" size="small"  className={classes.formControl}>
                                <Select
                                    labelId="select-outlined"
                                    id="shipping-select"
                                    size="small"
                                    value={variables.shippingOption}
                                    onChange={(event)=>handleChange("shippingOption",event.target.value)}
                                >
                                    <MenuItem  value={"ALL"}>All Provinces</MenuItem>
                                    <MenuItem value={"FEW"}>Selected Provinces</MenuItem>
                                </Select>
                            </FormControl>
                            {variables.shippingOption === "FEW" &&<Box pt={1}>
                                <div>
                                {allProvinces && allProvinces.length > 0 && <div style={{position:"relative"}}>
                                    <div style={{position:"absolute",width:"100%",overflow:"auto",height:200,backgroundColor:"yellow",zIndex:1000}}>
                                        {allProvinces.map((item,index)=>
                                        <div style={{width:"100%",height:50,padding:10,display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                            <p>{item.node.wilaya_name + " "+item.node.wilaya_code}</p>
                                            <div>
                                                <p onClick={()=>setSearchedProvince(item.node)} style={{backgroundColor:"#f6f6f7",padding:5,borderRadius:12,fontWeight:'bold',cursor:"pointer"}}>Add</p>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>}
                                {variables.selectedShipping && variables.selectedShipping.map((item,index)=>
                                    <div style={{width:"100%",height:50,padding:10,display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <p>{item.wilaya_name + " "+item.wilaya_code}</p>
                                        <div>
                                            <p onClick={()=> removeProvince(item)} style={{backgroundColor:"#f6f6f7",padding:5,borderRadius:12,fontWeight:'bold',cursor:"pointer"}}>X</p>
                                        </div>
                                    </div>)
                                }
                                </div>
                            </Box>}
                        </Box>}
                    </Box>
                    
                    <Box className={classes.cardForm}>
                        <Typography variant="h5">Minimum requirements</Typography>
                        <Box p={1}></Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                labelId="select-outlined"
                                id="requirement-select"
                                size="small"
                                value={minRequirement}
                                onChange={(event)=>setMinRequirement(event.target.value )}
                            >
                            <MenuItem  value={"NONE"}>None</MenuItem>
                            <MenuItem value={"MIN_PURCHASE_AMOUNT"}>Minimum purchase amount (BNB)</MenuItem>
                            <MenuItem  value={"MIN_QUANTITY"}>Minimum quantity of items</MenuItem>
                            </Select>
                        </FormControl>
                        {minRequirement === "MIN_PURCHASE_AMOUNT" &&
                        <Box p={2}>                            
                            <TextField value={variables.minPurchaseAmount} placeholder="0" onChange = {(event)=>handleChange("minPurchaseAmount",event.target.value)} variant="outlined" size="small" />
                            <Typography variant="body1">Applies to all products.</Typography>
                        </Box>
                        }
                        {minRequirement === "MIN_QUANTITY" &&
                        <Box p={2}>
                            <TextField value={variables.minQuantity} placeholder="0" onChange = {(event)=>setMinQuantity(event.target.value)} variant="outlined" size="small" />
                            <Typography variant="body1">Applies to all products.</Typography>
                        </Box>
                        }
                    </Box>
                    <Box className={classes.cardForm}>
                        <Typography variant="h5">Customer eligibility</Typography>
                        <Box p={1}></Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                labelId="select-outlined"
                                id="requirement-select"
                                size="small"
                                value={variables.selectCustomer}
                                onChange={(event)=>handleChange("selectCustomer",event.target.value )}
                            >
                            <MenuItem  value={"ALL_CUSTOMER"}>Everyone</MenuItem>
                            <MenuItem  value={"SPECIFIC_CUSTOMER"}>Specific customers</MenuItem>
                            </Select>
                        </FormControl>
                        {variables.selectCustomer === "SPECIFIC_CUSTOMER" &&
                        <Box p={2}>     
                            {allCustomers && allCustomers.length > 0 && <div style={{position:"relative"}}>
                                    <div style={{position:"absolute",width:"100%",overflow:"auto",height:200,backgroundColor:"yellow",zIndex:1000}}>
                                        {allCustomers.map((item,index)=>
                                        <div style={{width:"100%",height:50,padding:10,display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                            <p>{item.node.displayName}</p>
                                            <div>
                                                <p onClick={()=>setSearchedCustomer(item.node)} style={{backgroundColor:"#f6f6f7",padding:5,borderRadius:12,fontWeight:'bold',cursor:"pointer"}}>Add</p>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>}
                                {variables.selectedCustomers && variables.selectedCustomers.map((item,index)=>
                                    <div style={{width:"100%",height:50,padding:10,display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <p>{item.displayName}</p>
                                        <div>
                                            <p onClick={()=> removeCustomer(item)} style={{backgroundColor:"#f6f6f7",padding:5,borderRadius:12,fontWeight:'bold',cursor:"pointer"}}>X</p>
                                        </div>
                                    </div>)
                                }
                        </Box>
                        }
                    </Box>
                    <Box className={classes.cardForm}>
                        <Typography variant="h5">Usage limits</Typography>
                        <div style={{display:"flex",alignItems:"center"}}>
                            <Checkbox
                                checked={variables.limitUsageNumber}
                                onChange={(event)=>handleChange("limitUsageNumber",event.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography variant="body1">Limit number of times this discount can be used in total</Typography>
                        </div>
                        {variables.limitUsageNumber &&
                        <Box style={{marginLeft:40}}>
                            <TextField value={variables.usageNumber} placeholder={"usage number"} onChange = {(event)=>handleChange("usageNumber",event.target.value)} variant="outlined" size="small" />
                        </Box>}
                        <div style={{display:"flex",alignItems:"center"}}>
                            <Checkbox
                                checked={variables.limitUsageNumberCustomer}
                                onChange={(event)=>handleChange("limitUsageNumberCustomer",event.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography variant="body1">Limit to one use per customer</Typography>
                        </div>
                    </Box>
                    {variables.start && variables.end &&<Box className={classes.cardForm}>
                        <Typography variant="h5">Active dates</Typography>
                        <Typography variant="h6">Start Date</Typography>
                        <form className={classes.container} noValidate>
                            <TextField
                                InputProps={{disableUnderline : true}}
                                id="startdatetime-local"
                                type="datetime-local"
                                defaultValue={variables.start}
                                onChange={(event) => handleChange("start",event.target.value)}
                                fullWidth variant="outlined" size="small" 
                            />
                        </form>
                        <Typography variant="h6">End Date</Typography>
                        <form className={classes.datePikers} noValidate>
                            <TextField
                                InputProps={{disableUnderline : true}}
                                id="enddatetime-local"
                                type="datetime-local"
                                defaultValue={variables.end}
                                onChange={(event) => handleChange("end",event.target.value)}
                                fullWidth variant="outlined" size="small" 
                            />
                        </form>
                    </Box>}
                    <Box pt={2} style={{width:"50%",alignSelf:"center"}}>
                        <Button 
                            variant="contained" 
                            fullWidth 
                            color="secondary" 
                            disableElevation={true}
                            onClick={()=>dicount_update("update")}
                        >
                            {isCreating?<CircularProgress size={25} />:"Submit"}                        
                        </Button>
                    </Box>
                </Box>
                <Box className={classes.rightSide}>
                    <Box className={classes.cardForm}>
                    </Box>
                </Box>
            </div>
            
            {alertModal && <AlertModal/>}
        </Box>
    )
}

export default Discount