import React, { useEffect,useState } from 'react' 
import { Typography, TextField, Chip, Box} from '@material-ui/core' 
import { useStyles } from '../commonStyle'
import { useSelector} from "react-redux";
function RightSide(props) {
    const appProps = useSelector(state => state);
    const classes = useStyles()
    const {variables} = props
    return ( 
        <Box className={classes.cardForm}>
        </Box>
    )
} 
export default RightSide