export default {
    fontFamily : "Montserrat",
    h5: {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.05px'
    },
    h6: {
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: '0.5px', 
    },
    subtitle1 : {
        fontSize : 14,
        fontWeight : 700,
        letterSpacing: '0.5px', 
    },
    subtitle2 : {
        fontSize : 12,
        fontWeight : 700,
        letterSpacing: '0.5px', 
    },
    body1 : {
        fontSize : 14,
        fontWeight : 500,
        letterSpacing: '0.5px',           
    },
    body2 : {
        fontSize : 13,
        fontWeight : 500,
        letterSpacing: '0.2px',
    },
}