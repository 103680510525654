import React from 'react'
import { Box } from "@material-ui/core"
import {useStyles } from "./styles"
import {Outlet} from "react-router-dom"
import SideBar from '../components/sidebar/Sidebar'
import Header from '../components/header/Header'
import Footer from "../components/Footer/Footer"

function Pages() {
    const classes = useStyles()
    return (
        <Box display="flex">
            <Header />
            <SideBar />
            <Box className={classes.content}>
                <Box className={classes.toolbar} ></Box>
                <Outlet/>
                <Footer />
            </Box>
        </Box>
    )
}

export default Pages
