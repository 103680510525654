import React from 'react'
import { Box, Typography, makeStyles,Button } from "@material-ui/core"
import AddCircleIcon from '@material-ui/icons/AddCircle';
const useStyles = makeStyles((theme)=> ({
    subHeaderBtn : {
        /* border : "1px solid #8f58fd", */
        /* boxShadow : "1px 1px 15px #ccc", */
        padding : "4px 16px",
        borderRadius : 12,
        cursor : "pointer",
        "& .MuiSvgIcon-root" : {
            /* color : "#52ff00" */
        },
        "&:hover" : {
            transform : "scale(1.02)",
        }
    }
}))

function SubHeader({locationName, locationfunc, handleClick, actionBtn,actionBtnFnc}) {
    const classes =  useStyles()
    return (
        
        <Box 
        display="flex" 
        alignItems="center" 
        justifyContent="space-between" 
        >
            <Box flexGrow={1} >
                <Typography color="primary" variant="h5">{locationName}</Typography>
            </Box>
            <Box 
            className={classes.subHeaderBtn}
            onClick={handleClick}  
            display="flex" 
            alignItems="center"
            
            >
                {locationfunc ? 
                <>
                <AddCircleIcon color="secondary" /> 
                <Box pr={1}/>
                <Typography variant="subtitle1" color="secondary" >{locationfunc}</Typography> </> : null}
            </Box>
            { actionBtn && 
                <Box
                className={classes.subHeaderBtn}
                /* onClick={handleClick} */  
                display="flex" 
                alignItems="center"
                pl={2}
                >
                    <Button 
                        variant="contained" 
                        fullWidth 
                        color="secondary" 
                        disableElevation={true}
                        onClick={actionBtnFnc}
                    >
                        <Typography variant="subtitle1" style={{color:"#fff"}} >{actionBtn}</Typography>                   
                    </Button>
                </Box>
            }
        </Box>

    )
}

export default SubHeader
