export function tokenizeEmail(str) {
    let lowerEmail = str.toLowerCase()
    let email = lowerEmail.trim()
    return email
}

export const stringToInt=(str) =>{
    if(str) return Number(str.replace(/[^0-9.-]+/g,""))
    return str;
}
export const formatDate =(dt) => {
    const date = new Date(dt);
    const day = date.getDate()>10?date.getDate():"0"+date.getDate()
    const month = (date.getMonth()+1)>8 ? date.getMonth()+1:"0"+(date.getMonth()+1)
    const minutes = date.getMinutes() <10 ? "0"+ date.getMinutes():date.getMinutes()
    const hour = date.getHours()>10?date.getHours():"0"+date.getHours()
    const formatedDate = date.getFullYear() + "-" + month + "-" + day + "T" + hour + ":" + minutes
    return formatedDate
}
export const formatBirthDate =(dt) => {
    const date = new Date(dt);
    const day = date.getDate()>10?date.getDate():"0"+date.getDate()
    const month = (date.getMonth()+1)>10?date.getMonth()+1 : "0"+(date.getMonth()+1)
    const formatedDate = date.getFullYear() + "-" + month + "-" + day 
    return formatedDate
}

export function dayMonth (timestamp) {
    const dt = new Date(timestamp)
    const day  = dt.getDate()
    const month = dt.getMonth()
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return day +" " +months[month]
}