import React from 'react'
import {Grid, makeStyles, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"

const useStyles = makeStyles(theme => ({
    footerContainer : {
        padding : 24,
        marginTop : 24,
        borderRadius : 2,
        backgroundColor : "#FFF",
        boxShadow : "4px 4px 20px #ccc",
    },
    footerLinks : {
        display : "flex",
        justifyContent : "flex-end",
        "& a" : {
            textDecoration : "none",
            marginLeft : 16,
            color : theme.palette.text.secondary,
            "& :hover" : {
                color : theme.palette.secondary.main
            }
        }
    }
}))

function Footer() {
    const classes = useStyles()

    return (
        <Grid className={classes.footerContainer} container>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Typography color="textSecondary">2022 © FastlyNode</Typography>
            </Grid>
        </Grid>
    )
}

export default Footer
