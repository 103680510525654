import React, {useContext, useState} from 'react'
import { Context } from "../../context/AppContext"
import { AppBar, Toolbar,  Collapse, IconButton, Typography, Box, Avatar, Divider } from "@material-ui/core"
import { useStyles } from "./Styles"
import MenuIcon from "@material-ui/icons/Menu"
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from "clsx"
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useNavigate } from "react-router-dom"
import { useSelector,useDispatch} from "react-redux";
function Header() {
    const appProps = useSelector(state => state);
    const { sideBarOpen, setSideBarOpen } = useContext(Context)
    const [ dropOpen, setDropOpen ] = useState(false)
    const [ userDrop, setUserDrop ] = useState(false)
    const navigate =  useNavigate()
    const classes = useStyles()
    const dispatch = useDispatch();
    const [ logingOut, setLogingOut ] = useState(true)
    const handleDrawerOpen = () => {
        setSideBarOpen(true);
    }
    const logOut =async() =>{
        setLogingOut(true)
        const logout = removeCredential('userCredential')
        if (!logout) {
          alert('something went wrong')
        }
        setLogingOut(false)
        if (logout) {
          const action = { type: "USER_CREDENTIAL", value: null }
          dispatch(action)
        }
        
    }
    const removeCredential=async(key)=>{
        try {
            await localStorage.removeItem(key);
            return true;
        }
        catch(exception) {
            return false;
        }
    }
    return (
        <AppBar
            color="secondary"
            position="fixed"
            elevation={8}
            className={clsx(classes.appBar, {
            [classes.appBarShift]: sideBarOpen,
            })}
        >
            <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                [classes.hide]: sideBarOpen,
                })}
            >
                <MenuIcon />
            </IconButton>
            <Box onClick={()=> setDropOpen(!dropOpen)} 
                className={classes.createNew}
            >
                <Typography variant="subtitle1" noWrap style={{color:"#fff"}}>
                    Create new
                </Typography>
                <ArrowDropDownIcon style={{color:"#fff"}}/>

                <Collapse in={dropOpen} className={classes.createNewDropdown} >
                    <Box className={classes.dropwrapper} >
                    <Box 
                        onClick={()=> navigate('/create-discount')}
                        display="flex" 
                        alignItems="center"
                        >
                        <PersonIcon/>
                        
                        <Typography  variant="body2" style={{color:"#fff"}}>Discount</Typography>
                    </Box>
                    </Box>
                </Collapse>
            </Box>
            <Box className={classes.userId} display="flex" alignItems="center" onClick={()=>setUserDrop(!userDrop)} >
                <Avatar><Typography variant="h6">A</Typography></Avatar>
                <Box pl={1}></Box>
                {appProps.userInfo&&<Typography variant="subtitle1">{appProps.userInfo.userName}</Typography>}
                <ArrowDropDownIcon  />
                <Collapse in={userDrop} className={classes.createNewDropdown} >
                    <Box className={classes.dropwrapper} >
                    <Box 
                        onClick={()=> navigate('/myaccount')}
                        display="flex" 
                        alignItems="center"
                        >
                        <PersonIcon/>
                        
                        <Typography  variant="body2" >My account</Typography>
                    </Box>
                    <Box
                        onClick={()=> navigate('/setting')}
                         display="flex" 
                         alignItems="center"
                         >
                            <SettingsIcon/>
                            <Typography  variant="body2" >Setting</Typography>
                    </Box>
                        <Box component="em" pb={1}></Box>
                        <Divider style={{backgroundColor : "#888"}} />
                        <Box onClick={()=>logOut()} display="flex" alignItems="center">
                            <ExitToAppIcon/>
                            <Typography  variant="body2" >Logout</Typography>
                        </Box>
                        
                    </Box>
                </Collapse>
            </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Header
