import React,{useState,useContext,useRef} from 'react'
import { Button, Box, CircularProgress,Typography,TextField} from "@material-ui/core"
import JoditEditor from "jodit-react";
import { useSelector} from "react-redux";
import { useStyles } from '../commonStyle'
import {Context} from "../../context/AppContext"
import AlertModal from "../../components/AlertModal/AlertModal"
import SubHeader from "../../components/SubHeader/SubHeader"

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { emailSend } from '../../APi/api';
function AddEmail() {
    const editor = useRef(null)
    const classes = useStyles()
    const appProps = useSelector(state => state);
    const {alertModal, setAlertModal} = useContext(Context)
    
    const [isCreating, setCreating] = useState(false)
    const [variables, setVariables] = React.useState({
        content:"",
        receiver:"ALL",
        subject:""
    });
    const handleChange = (target,value) => {
        setVariables({ ...variables, [target]: value });
        console.log(variables);
    };
    const config = {
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
        askBeforePasteHTML:false,
        askBeforePasteFromWord:false,
        defaultActionOnPaste:"insert_clear_html"
	}
    const create_email=async()=>{
        setCreating(true)
        const response = await emailSend(appProps.apolloClient,variables)
        if(response.success) {
            showAlert("Success","Email sent successfully")
        } else {
            showAlert("Error",response.error.message)
        }
        setCreating(false)
    }
    const showAlert = (type,message)=>{
        setAlertModal({type:type,message:message})
    }
    return (
        <Box className={classes.container}>
            <SubHeader locationName={"Create Email"} />
            <div className={classes.mainContainer}>
                <Box className={classes.cardForm}>
                    <Box>
                        <Box pt={1}>
                            <Typography variant="h5">Subject</Typography>
                            <TextField value={variables.subject} placeholder="e.g New Promotion" onChange = {(event)=>handleChange('subject',event.target.value)} fullWidth variant="outlined" size="small" />
                        </Box>
                    
                        <Box pt={1}>
                            <Typography variant="h5">Email</Typography>
                            <JoditEditor
                                ref={editor}
                                value={variables.content}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(value)=>handleChange('content',value)}
                                
                            />
                        </Box>
                        <Box pt={1}>
                            <Typography variant="h5">Receivers</Typography>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    labelId="select-outlined"
                                    id="statu-select"
                                    size="small"
                                    value={variables.receiver}
                                    onChange={(event)=>handleChange("receiver",event.target.value)}
                                >
                                <MenuItem  value={"ALL"}>All</MenuItem>
                                <MenuItem value={"NOORDER"}>Has no order</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        
                    </Box>
                </Box>
            </div>
            <Box pt={2} style={{width:"50%",alignSelf:"center"}}>
                        <Button 
                            variant="contained" 
                            fullWidth 
                            color="secondary" 
                            disableElevation={true}
                            onClick={()=>create_email()}
                        >
                            {isCreating?<CircularProgress size={25} />:"Send"}                        
                        </Button>
            </Box>
            {alertModal && <AlertModal/>}
        </Box>
    )
}

export default AddEmail
