import React, { useContext } from 'react';
import { Context } from "../../context/AppContext"
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CardGiftcard from '@material-ui/icons/CardGiftcard'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useStyles } from "./Styles"
import DashboardIcon from '@material-ui/icons/Dashboard';
import { Box, Avatar, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StorefrontIcon from '@material-ui/icons/Storefront';
import Person from '@material-ui/icons/Person'
import { useNavigate } from "react-router-dom"
export default function SideBar() {
  const { sideBarOpen, setSideBarOpen } = useContext(Context)
  const navigate = useNavigate()
  const classes = useStyles();
  const handleDrawerClose = () => {
    setSideBarOpen(false);
  };

  return (
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: sideBarOpen,
          [classes.drawerClose]: !sideBarOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: sideBarOpen,
            [classes.drawerClose]: !sideBarOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon color="inherit" />
          </IconButton>
        </div>
        <Divider />
        <List>
            <ListItem button >
                <ListItemIcon><Avatar><Typography variant="h6">A</Typography></Avatar></ListItemIcon>
                <ListItemText primary={"Fastly Admin"} />
            </ListItem>
            <Divider/>
            <Box p={1}></Box>
            <ListItem button onClick={()=> navigate("/")} >
                <ListItemIcon><DashboardIcon color="secondary" /></ListItemIcon>
                <ListItemText primary={<Typography variant="h6">Dashboard</Typography>} />
            </ListItem> 
            <ListItem button onClick={()=>navigate("/discounts")}>
              <ListItemIcon><CardGiftcard /></ListItemIcon>
              <ListItemText primary={<Typography variant="h6">Discounts</Typography>} />
            </ListItem>
            <ListItem button onClick={()=>navigate("/customers")}>
              <ListItemIcon><Person /></ListItemIcon>
              <ListItemText primary={<Typography variant="h6">Customers</Typography>} />
            </ListItem>
            <ListItem button onClick={()=>navigate("/create-email")}>
              <ListItemIcon><Person /></ListItemIcon>
              <ListItemText primary={<Typography variant="h6">Send Email</Typography>} />
            </ListItem>
        </List>
      </Drawer> 
  );
}
