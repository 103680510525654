
export default {
    primary : {
        main : "#6534ac"
    },
    secondary : {
        /* main : "#6145ef" */
        main : "#021048"
    },
    white : {
        main:"#fff"
    },
    text : {
        primary : "#4E4B63",
        secondary : "#BBB"
    },
    background : {
        default : "#fff",
    }
}
