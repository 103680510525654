import React, { createContext, useState } from "react"

export const Context = createContext()

function AppContext({children}) {
    const [sideBarOpen, setSideBarOpen] = useState(false)
    const [alertModal, setAlertModal] = useState(null)
    const [actionModal, setActionModal]= useState(null)
    return (
        <Context.Provider value={{
            sideBarOpen, setSideBarOpen,alertModal, setAlertModal,
            actionModal, setActionModal,
        }}>
            {children}
        </Context.Provider>
    )
}

export default AppContext