
const initialState = { userCredential:null,userInfo:null,apolloClient:null}
function toggleConfig(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_APOLLO':
        nextState = {
          ...state,
          apolloClient: action.value
        }
    return nextState || state
    case 'USER_INFORMATION':
        nextState = {
          ...state,
          userInfo: action.value
        }
    return nextState || state
    case 'USER_CREDENTIAL':
        nextState = {
          ...state,
          userCredential: action.value
        }
    return nextState || state
    
    default:
      return state
  }
}
export default toggleConfig
