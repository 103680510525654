import { gql } from '@apollo/client';
export const ADMIN_LOGIN = gql`
  mutation LoginAdmin($email:String!,$password:String!){
    loginAdmin(email:$email,password:$password) {
      success
      token
      error {
        code
        message
      }
    }
  }
`;


export const DISCOUNT_CODE_CREATE = gql`
  mutation priceRuleCreate ($priceRule: PriceRuleInput!, $priceRuleDiscountCode:PriceRuleDiscountCodeInput! ) {
    priceRuleCreate (priceRule: $priceRule, priceRuleDiscountCode: $priceRuleDiscountCode) {
        priceRule {
          id
        }
        priceRuleDiscountCode {
          id
          code
          usageCount
        }
        priceRuleUserErrors {
          code
          field
          message
        }
        success
        error{
          code
          message
        }
    }
  }
`;

export const DISCOUNT_CODE_UPDATE = gql`
  mutation priceRulesUpdate ($priceRule: PriceRuleInput!) {
    priceRulesUpdate (priceRule: $priceRule) {
        priceRule {
          id
        }
        priceRuleDiscountCode {
          id
          code
          usageCount
        }
        priceRuleUserErrors {
          code
          field
          message
        }
        success
        error{
          code
          message
        }
    }
  }
`;
export const EMAIL_SEND = gql`
  mutation emailSend ($content:String!,$receiver:CustomerReceiver!,$subject:String!) {
    emailSend (content:$content,receiver:$receiver,subject:$subject) {
        success
        error{
          code
          message
        }
    }
  }
`;
