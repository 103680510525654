import { makeStyles } from "@material-ui/core"
const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    "& .MuiSvgIcon-root" : {
      color : '#fff'
    },
    "& .Mui-disabled" : {
      backgroundColor : theme.palette.secondary.main,
      "& .MuiSvgIcon-root" : {
        color : '#fff'
      }
    },
    "& .MuiSvgIcon-root, img" : {
        marginLeft : 8,
    },
    "& .MuiTypography-root" : {
      color: "#fff"
    },
    "& .MuiListItem-root" : {
      "& .MuiPaper-root" : {
        backgroundColor : theme.palette.secondary.main
      },
      "&:hover" : {
        backgroundColor : theme.palette.secondary.main,
      }
    }
  },
  drawerOpen: {

    backgroundColor : theme.palette.secondary.main,
    border : "none",
    boxShadow : "3px 1px 15px #efefef",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& .MuiListItem-root" : {
      paddingBottom:"2px",
      paddingTop:"2px",
      "&:hover" : {
        borderBottom : "1px solid #DEDEDE"
      }
    }
  },
  drawerClose: {

    backgroundColor : theme.palette.secondary.main,
    
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor : theme.palette.secondary.main,
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    /* backgroundColor : "#6145ef", */
    "& .MuiSvgIcon-root" : {
        color : "#fff"
    },
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  dashDropdown : {
    border : "none",
    "& .MuiAccordionSummary-root" : {
      border : "none"
    },
    "& .MuiButtonBase-root" : {
      padding : 0,
    },
    "& .MuiListItemIcon-root" : {
      marginTop : 4
    }
  },
  accordionList : {
    marginLeft : "40px",
    display : "flex",
    flexDirection : "column",
    paddingBottom : 0,
    paddingTop : 0,
    "& p" : {
      paddingBottom : 10,
      "&:hover" : {
        borderBottom : "1px solid #DEDEDE"
      }
    },
    
  }
}));