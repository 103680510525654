import React,{useState,useContext,useRef} from 'react'
import { Typography, Box,makeStyles,Button,CircularProgress} from "@material-ui/core"
import PropTypes from 'prop-types';
function Btn({text,onSubmit,isCreating}){
    return (
        <Box>
            <Button 
                variant="contained" 
                fullWidth
                color="secondary" 
                disableElevation={true}
                onClick={onSubmit}
            >
                {isCreating?<CircularProgress size={25} />:<Typography variant="h5">{text}</Typography>}                        
            </Button>
        </Box>
    )
}
Btn.defaultProps = {
    text: 'Submit',
    isCreating:false,
    onSubmit:()=>{console.log("default");},
}
Btn.propTypes = {
    text: PropTypes.string,
    isCreating: PropTypes.bool,
    onSubmit:PropTypes.func,
};
export default Btn