import { makeStyles } from "@material-ui/core"

const drawerWidth = 240;
export const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    createNew : {
        display :"flex",
         alignItems :"center" ,
        flexGrow : 1 ,
        cursor : "pointer"
    },
    createNewDropdown : {
        borderRadius : "0px 0px 2px 2px",
        position : "absolute",
        top : 64,
        zIndex : 10,
        backgroundColor : theme.palette.secondary.main,
        boxShadow : "1px 10px 15px 0px #ccc",
        /* padding : "8px 0px", */
        "& .MuiSvgIcon-root" : {
            color : "#fff",
            width : 15,
            marginRight : 12
        }
    },
    dropwrapper : {
        "& .MuiBox-root" : {
            padding : "8px 42px 8px 16px",
           "&:hover" : {
               backgroundColor : theme.palette.secondary.dark
           }
        },
        "& hr" : {
            backgroundColor : "#dedede"
        }
    },
    userId : {
        cursor : "pointer"
    }
}))