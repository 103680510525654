import CssBaseline from '@material-ui/core/CssBaseline';
import {Route,Routes,useNavigate } from "react-router-dom"
import { ThemeProvider } from "@material-ui/styles"
import { useSelector} from "react-redux";
import {customTheme } from "./theme/Theme"
import AppContext from "./context/AppContext"
import Pages from "./pages/Pages"
import Login from "./pages/Auth/Login"
import Customers from "./pages/Customers/Customers"
import Customer from "./pages/Customer/Customer"
import Discount from './pages/Discount/Discount';
import AddDiscount from './pages/AddDiscount/AddDiscount';
import Discounts from './pages/Discounts/Discounts';
import Dashboard from './pages/Dashboard/Dashboard';
import { useEffect } from 'react';
import AddEmail from './pages/AddEmail/AddEmail';

function App() {
  const appProps = useSelector(state => state);
    const navigate = useNavigate()
    useEffect(()=>{
      if (appProps.userCredential == null) {
          navigate("/login")
      }
  },[appProps])
  return (
    
      <AppContext>
        <ThemeProvider theme={ customTheme() }>
            <CssBaseline />
            <Routes>
            
            <Route path="/" element={<Pages />}>
              <Route path="" element={<Dashboard/>} />
              <Route exact path="/discounts" element={<Discounts/>}/>
              <Route path="/discounts/:id" element={<Discount/>}/>
              <Route path="/create-discount" element={<AddDiscount/>}/>
              <Route path="/create-email" element={<AddEmail/>}/>
              <Route path="/customers" element={<Customers/>}/>
              <Route path="/customers/:id" element={<Customer/>}/>
              
            </Route>
            
            <Route path="/login" element={<Login />} />
            </Routes>
          </ThemeProvider>
          
      </AppContext>
  );
}

export default App;
